<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          <span v-if="invoice.status === 1">Application</span>
          <span v-else>Invoice</span>
          #{{ invoice.jobNumber }}
          &nbsp;|&nbsp;Invoice status: <span class="highlight">{{ invoice.statusWord }}</span>
          <span class="float-right">View</span>
        </h1>
        <p v-if="invoice.vatStatus === 1">
          This invoice is issued in compliance with HMRC Domestic Reverse Charging Scheme for the Construction Industry
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <router-link
          custom v-slot="{ navigate }"
          :to="`/invoices/print-view/${invoice.id}`">
          <v-btn
            small
            @click="navigate"
            @keypress.enter="navigate"
            role="link"
            class="relative"
            style="top:-4px">
              Print View
          </v-btn>
        </router-link>
        &nbsp;
        <router-link
          custom v-slot="{ navigate }"
          :to="`/live-jobs/overview/${invoice.quotationId}`">
          <v-btn
            small
            @click="navigate"
            @keypress.enter="navigate"
            role="link"
            class="relative"
            style="top:-4px">
              Job Overview
          </v-btn>
        </router-link>
        &nbsp;
        <router-link
          custom v-slot="{ navigate }"
          :to="`/live-jobs/view/${invoice.quotationId}`">
          <v-btn
            small
            @click="navigate"
            @keypress.enter="navigate"
            role="link"
            class="relative"
            style="top:-4px">
              Job Card
          </v-btn>
        </router-link>
        &nbsp;
        <router-link
          custom v-slot="{ navigate }"
          :to="`/invoices/credit-note/${invoice.id}`">
          <v-btn
            small
            color="red"
            @click="navigate"
            @keypress.enter="navigate"
            role="link"
            class="relative"
            style="top:-4px">
              Raise Credit Note
          </v-btn>
        </router-link>
        &nbsp;
        <v-btn
          v-if="changesToSave"
          small
          @click="saveInvoice"
          class="relative"
          style="top:-4px">
            Save Changes
        </v-btn>
        <span v-if="changesToSave">&nbsp;</span>
        <v-btn
          v-if="invoice.status === 1"
          small
          @click="convertToInvoice"
          class="relative"
          style="top:-4px">
            Convert to invoice
        </v-btn>
        <span v-if="invoice.status === 1">&nbsp;</span>
        <v-btn
          v-if="invoice.status === 2"
          small
          @click="finaliseInvoice"
          class="relative"
          style="top:-4px">
            Finalise and post to Sage
        </v-btn>
        <span v-if="invoice.status === 2">&nbsp;</span>
        <v-btn
          v-if="invoice.status === 3"
          color="red"
          small
          @click="uninvoiceWarning = true"
          class="relative"
          style="top:-4px">
            Uninvoice
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
          <v-text-field v-model="invoice.customer_name"></v-text-field>
          <span v-if="invoice.addressOne">
            <v-text-field v-model="invoice.addressOne" label="Address line one"></v-text-field>
          </span>
          <span v-if="invoice.addressTwo">
            <v-text-field v-model="invoice.addressTwo" label="Address line two"></v-text-field>
          </span>
          <span v-if="invoice.addressThree">
            <v-text-field v-model="invoice.addressThree" label="Address line three"></v-text-field>
          </span>
          <span v-if="invoice.addressFour">
            <v-text-field v-model="invoice.addressFour" label="Address line four"></v-text-field>
          </span>
          <span v-if="invoice.addressFive">
            <v-text-field v-model="invoice.addressFive" label="Address line five"></v-text-field>
          </span>
          <span v-if="invoice.postcode">
            <v-text-field v-model="invoice.postcode" label="Postcode"></v-text-field>
          </span>
          <vue-editor
            id="additionalCodes"
            :editor-toolbar="customToolbar"
            v-model="invoice.additional_codes" />
      </v-col>
      <v-col class="col-6">
        <p>
          Document number: {{ invoice.jobNumber }}
        </p>
        <p>
          <v-text-field label="PO Number" v-model="invoice.poNumber"></v-text-field>
        </p>
        <p>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDateFormattedDatefns"
                clearable
                label="Invoice date / tax point"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="invoice.date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="invoice.date"
              @change="menu = false"
            ></v-date-picker>
          </v-menu>
        </p>
        <p>
          Account reference: {{ invoice.accountReference }}
        </p>
        <p>
          <v-textarea
            v-model="invoice.notes"
            label="Internal notes (will not show on print view)"
            rows="3"></v-textarea>
        </p>
      </v-col>
    </v-row>
    <v-row class="pt-5">
      <v-col class="col-12">
        <v-simple-table dense>
          <thead>
            <tr>
              <th>Nominal</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(nominal, index) in invoice.nominals" :key="`n_${index}`">
              <td>
                <span v-if="nominal.nominal_code === '4000'">
                  4000 Sale of goods
                </span>
                <span v-if="nominal.nominal_code === '4001'">
                  4001 Thermo
                </span>
                <span v-if="nominal.nominal_code === '4003'">
                  4003 Paint
                </span>
                <span v-if="nominal.nominal_code === '4008'">
                  4008 Anti-skid
                </span>
                <span v-if="nominal.nominal_code === '4015'">
                  4015 Hydroblasting
                </span>
                <span v-if="nominal.nominal_code === '4020'">
                  4020 Hydroblasting (Sub-contracted)
                </span>
                <span v-if="nominal.nominal_code === '4025'">
                  4025 Shotblasting
                </span>
                <span v-if="nominal.nominal_code === '4030'">
                  4030 Traffic Management
                </span>
              </td>
              <td>{{ nominal.amount | priceInPounds | currency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col class="col-12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Description</th>
                <th class="text-left">Material</th>
                <th class="text-left">Colour</th>
                <th class="text-left">Quantity</th>
                <th class="text-left">Units</th>
                <th class="text-left">Rate</th>
                <th class="text-left">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in invoice.lines" :key="index">
                <td>{{ line.description }}</td>
                <td>{{ line.material }}</td>
                <td>{{ line.colour }}</td>
                <td>{{ line.quantity }}</td>
                <td>{{ line.units }}</td>
                <td>{{ line.pounds || currency }}</td>
                <td>{{ (line.quantity * line.pounds) | currency }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col class="col-6">
        <vue-editor
          id="descriptionNotes"
          :editor-toolbar="customToolbar"
          v-model="invoice.description_notes" />
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col class="col-6">
        <p>
          <v-text-field v-model="invoice.siteLocation" label="Delivery location"></v-text-field>
        </p>
        <p>
          <v-text-field v-model="invoice.workDescription" label="Description"></v-text-field>
        </p>
        <p>
          <v-select
            v-model="invoice.contact_id"
            label="Contact"
            :items="contacts"
            item-text="full_name"
            item-value="id"></v-select>
        </p>
      </v-col>
      <v-col class="col-6 pr-20">
        <p>
          Sub-total:
          <span class="float-right">
            {{ subTotal | priceInPounds | currency }}
          </span>
        </p>
        <p>
          VAT @20%:
          <span v-if="invoice.status !== 1" class="float-right">
            <span v-if="invoice.vatStatus === 2">
              {{ vat | priceInPounds | currency }}
            </span>
          </span>
        </p>
        <p>
          Total:
          <span class="float-right">
            {{ (subTotal + vat) | priceInPounds | currency }}
          </span>
        </p>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="uninvoiceWarning"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          You are about to uninvoice this invoice.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="uninvoice"
          >
            Confirm
          </v-btn>
          <v-btn
            text
            @click="uninvoiceWarning = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { format, parseISO } from 'date-fns';
import { VueEditor } from "vue2-editor";
import axios from '../../axios';

export default {
  name: 'InvoicesView',
  components: {
    VueEditor,
  },
  data() {
    return {
      changesToSave: false,
      changeNumber: 0,
      invoice: {
        lines: [],
        date: '',
      },
      contacts: [],
      menu: false,
      startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      subTotal: 0,
      vat: 0,
      message: '',
      showMessage: false,
      showConfirmInvoice: false,
      showInvoiceFinalised: false,
      nominals: {
        one: 0, two: 0, three: 0, four: 0, five: 0, six: 0, seven: 0, eight: 0,
      },
      colours: [],
      materials: [],
      customToolbar: [
        ["bold", "italic", "underline"],
      ],
      uninvoiceWarning: false,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    computedDateFormattedDatefns () {
      return this.invoice.date ? format(parseISO(this.invoice.date), 'EEEE, MMMM do yyyy') : ''
    },
    userid() {
      return this.$store.state.userid;
    }
  },
  watch: {
    startDate () {
      this.invoice.date = this.formatDate(this.startDate);
    },
    invoice: {
      deep: true,
      handler() {
        let subtotal = 0;
        for ( let i = 0; this.invoice.lines.length > i; i += 1) {
          const lineTotal = this.invoice.lines[i].quantity * this.invoice.lines[i].pounds;
          subtotal += lineTotal;
        }
        this.subTotal = Math.round(subtotal * 100);
        if (this.changeNumber > 1) {
          this.changesToSave = true;
        }
        this.changeNumber += 1;
      },
    },
  },
  methods: {
    uninvoice() {
      const invoiceId = this.$route.params.id;
      axios.get(`/invoices/uninvoice/${invoiceId}.json?token=${this.token}`)
        .then(() => {
          this.$router.push('/live-jobs/awaiting-accounts');
        });
    },
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    saveInvoice() {
      const postData = {};
      postData.invoice = this.invoice;
      postData.nominals = this.nominals;
      postData.userid = this.userid;
      axios.post(`/invoices/saveUpdates.json?token=${this.token}`, postData)
        .then((response) => {
          this.invoice = response.data.invoice;
          this.message = response.data.message;
          this.showMessage = true;
        });
    },
    getColours() {
      axios.get(`/colours/getAll.json?token=${this.token}`)
        .then((response) => {
          this.colours = response.data;
          this.getMaterials();
        });
    },
    getMaterials() {
      axios.get(`/materials/getAll.json?token=${this.token}`)
        .then((response) => {
          this.materials = response.data;
        });
    },
    getInvoice() {
      axios.get(`/invoices/getSingle/${this.$route.params.id}.json?token=${this.token}`)
      .then((response) => {
        this.invoice = response.data;
        this.subTotal = response.data.value;
        this.vat = response.data.vat;
        this.contacts = response.data.contacts;
        this.updateNominals(response.data.nominals);
      });
    },
    updateNominals(nominals) {
      for(let i = 0; nominals.length > i; i +=1) {
        if (nominals[i].nominal_code === '4000') {
          this.nominals.one = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4001') {
          this.nominals.two = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4003') {
          this.nominals.three = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4008') {
          this.nominals.four = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4015') {
          this.nominals.five = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4020') {
          this.nominals.six = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4025') {
          this.nominals.seven = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4030') {
          this.nominals.eight = (nominals[i].amount / 100);
        }
      }
    },
  },
  mounted() {
    this.getColours();
    this.getInvoice();
  },
};
</script>